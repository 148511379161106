.sideBarText {
  font-size: 14px !important;
  text-decoration: none;
}

.sideBarBtn {
  color: #fff;
  border-radius: 4rem;
  border-color: #fff;
  padding: 0.7rem 2rem;
  font-weight: bold;
}

.headFontWeight {
  font-weight: 700 !important;
}

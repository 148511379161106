.btn_outline_action_2 {
  color: #2f1893;
  border-color: #2f1893;
  border-radius: 5rem !important;
  padding: 0.5rem 1.2rem;
  font-weight: bolder;
}
.btn_outline_action_2:hover {
  color: #ffffff;
  background-color: #2f1893;
  border-color: #2f1893;
}
.btn-check:focus + .btn_outline_action_2,
.btn_outline_action_2:focus {
  box-shadow: 0 0 0 0 rgba(47, 24, 147, 0.5);
}
.btn-check:checked + .btn_outline_action_2,
.btn-check:active + .btn_outline_action_2,
.btn_outline_action_2:active,
.btn_outline_action_2.active,
.btn_outline_action_2.dropdown-toggle.show {
  color: #ffffff;
  background-color: #2f1893;
  border-color: #2f1893;
}
.btn-check:checked + .btn_outline_action_2:focus,
.btn-check:active + .btn_outline_action_2:focus,
.btn_outline_action_2:active:focus,
.btn_outline_action_2.active:focus,
.btn_outline_action_2.dropdown-toggle.show:focus {
  box-shadow: 0 0 0 0 rgba(47, 24, 147, 0.5);
}
.btn_outline_action_2:disabled,
.btn_outline_action_2.disabled {
  color: #2f1893;
  background-color: transparent;
}

.text_dark_1 {
  --bs-text-opacity: 1;
  color: rgba(var(--bs-dark-1-rgb), var(--bs-text-opacity)) !important;
}

/* Fill color property for SVG icons */
.fill_dark_1 {
  fill: #1e0e62;
}

.tuefectivo {
  font-weight: bold;
}

.boldFooter {
  font-weight: bold;
}

.holaText {
  font-size: small !important;
}

.formDivMain {
  display: flex;
}

.formImgDiv {
  padding: 1.2rem;
}

.formDiv {
  padding: 1rem;
}

.formText1 {
  font-family: Baloo 2;
  font-weight: 700;
  line-height: 1.25;
  color: #1e0e62 !important;
  width: 60%;
  margin: 0 auto;
}

.formText2 {
  letter-spacing: -0.03em;
  width: 60%;
  margin: 0 auto;
}

.displayNone {
  display: none;
}

.btn_outline_action_1 {
  color: #25dac5;
  border-color: #25dac5;
}
.btn_outline_action_1:hover {
  color: #1e0e62;
  background-color: #25dac5;
  border-color: #25dac5;
}

.inpForm1 {
  outline: none;
  border: none;
}

.inpIcon {
  padding: 1rem;
  margin-top: 6px;
}

.inpText {
  font-size: 13px !important;
}

.inpContainer {
  display: flex;
  align-items: center;
  /* padding: 0.5rem; */
  border: 1px solid gray;
  border-radius: 5rem;
  opacity: 0.5;
  height: 3rem !important;
}

.inpError {
  border: 2px solid red;
}

.inpContainer input::placeholder {
  margin-bottom: 0.4rem;
}

.svgIcon {
  color: gray;
}

.FormBtn {
  border-radius: 7rem !important;
  padding: 0.8rem 0rem !important;
}

/* checkbox */

.container {
  display: block;
  position: relative;
  padding-left: 35px;
  margin-bottom: 12px;
  cursor: pointer;
  font-size: 22px;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

/* hide browsers default input */
.container input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
/* Create a custom checkbox */
.checkmark {
  position: absolute;
  top: 0;
  left: 0;
  height: 25px;
  width: 25px;
  background-color: #eee;
  border-radius: 7rem;
}

/* On mouse-over, add a grey background color */
.container:hover input ~ .checkmark {
  background-color: #ccc;
}

/* When the checkbox is checked, add a blue background */
.container input:checked ~ .checkmark {
  background-color: #25dac5;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
  content: '';
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.container input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.container .checkmark:after {
  left: 9px;
  top: 5px;
  width: 8px;
  height: 15px;
  border: solid white;
  border-width: 0 3px 3px 0;
  -webkit-transform: rotate(45deg);
  -ms-transform: rotate(45deg);
  transform: rotate(45deg);
}

.AceptoLos {
  font-size: 1rem;
}

.modalButton {
  padding: 0.5rem 1.5rem !important;
  font-weight: bold !important;
}

.modalButton:hover {
  color: #fff !important;
}
